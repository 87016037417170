<template>
  <div>
    <div class="slideBg">
      <div class="container">
        <div class="user_info" v-if="Me">
          <div class="head_img">
            <div class="avatar"><img src="img/head_temp.jpg" /></div>
            <a class="edit_avatar" href="javascript:void(0)" @click="$router.push('/profile')">
              <h4>編輯個人資料</h4>
              <div class="editPen"><img src="img/edit.svg" /></div>
            </a>
          </div>
          <div class="name_level">
            <h2>{{ Me.Name }}</h2>
            <h3 v-if="Me.UserType && Me.UserType.length > 0">
              身份別：<span>{{ Me.UserType[0] | userTypeFormat }}</span>
            </h3>
            <h3 v-if="false">
              職稱：<span>{{ Me.Job }}</span>
            </h3>
          </div>
          <div class="point">
            <h3>
              可使用點數：<b>{{ Me.Point | commaFormat }}</b>
            </h3>
            <h3>
              無期限點數：<b>{{ UnlimitedPoint | commaFormat }}</b>
            </h3>
            <h3 v-if="LimitedPoint">
              有期限點數：<b>{{ LimitedPoint | commaFormat }} </b>
              <span>{{ LimitedDeadline | dateFormat }}到期</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="breadcrumb">
      <div class="page_member_info">
        <a class="logOut" href="javascript:void(0)" @click="logout">
          <span>登出</span></a>
      </div>
      <ul>
        <li><a href="/">首頁</a></li>
        <li><a href="javascript:void(0)">我的帳號</a></li>
      </ul>
    </div>
    <div class="main">
      <div class="container">
        <div class="mTable">
          <div class="mTab">
            <a class="on" data-target="mtc_01" href="javascript:void(0)">觀看紀錄</a><a data-target="mtc_03"
              href="javascript:void(0)">我的收藏</a><a data-target="mtc_02" href="javascript:void(0)">點數使用紀錄</a>
            <a data-target="mtc_04" href="javascript:void(0)" v-if="false">點數期限</a>
            <a data-target="mtc_05" href="javascript:void(0)" v-show="IsLearner">證照效期</a>
          </div>
          <div class="mTableContant">
            <div class="mtc_01">
              <table class="tableStyle1">
                <tr>
                  <th>購買日期</th>
                  <th>課程名稱</th>
                  <th>使用期限</th>
                  <th>剩餘時間</th>
                </tr>
                <tr v-if="Lesson.length == 0">
                  <td colspan="4">沒有購買的課程</td>
                </tr>
                <tr v-for="l in Lesson" :key="l.UserLessonID">
                  <td>{{ l.CreateTime | dateFormat }}</td>
                  <td>{{ l.Lesson.Title }}</td>
                  <td>{{ l.ExpireTime | dateFormat }}</td>
                  <td>
                    <div class="status">
                      {{ l.LeftSeconds | hhmmss
                      }}<a class="goBtn" href="javascript:void(0)"
                        @click="$router.push('/lesson/' + l.LessonID)">上課去</a>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div class="mtc_03">
              <table class="tableStyle1">
                <tr>
                  <th>加入日期</th>
                  <th>課程名稱</th>
                  <th>使用點數</th>
                  <th>購買</th>
                  <th>刪除</th>
                </tr>
                <tr v-if="Favorite.length == 0">
                  <td colspan="6">沒有收藏的課程</td>
                </tr>
                <tr v-for="l in Favorite" :key="l.FavoriteLessonID">
                  <td>{{ l.CreateTime | dateFormat }}</td>
                  <td>{{ l.Lesson.Title }}</td>
                  <td>{{ l.Lesson.Price | commaFormat }}</td>
                  <td>
                    <a class="checkOut" href="javascript:void(0)" @click="$router.push('/lesson/' + l.LessonID)"><img
                        src="img/CheckOut.svg" /></a>
                  </td>
                  <td>
                    <a class="delete" href="javascript:void(0)" @click="deleteFavorite(l)"><img
                        src="img/delete.svg" /></a>
                  </td>
                </tr>
              </table>
            </div>
            <div class="mtc_02">
              <table class="tableStyle1">
                <tr>
                  <th>日期</th>
                  <th>購買課程</th>
                  <th>加值點數</th>
                  <th>使用點數</th>
                  <th>剩餘點數</th>
                  <th>點數使用期限</th>
                </tr>
                <tr v-if="Point.length == 0">
                  <td colspan="4">沒有點數紀錄</td>
                </tr>
                <tr v-for="l in Point" :key="l.PointLogID">
                  <td>{{ l.CreateTime | dateFormat }}</td>
                  <template v-if="l.Difference < 0">
                    <td>
                      {{ l.Description }}
                    </td>
                    <td>-</td>
                    <td>
                      <b class="use_point">{{
                        Math.abs(l.Difference) | commaFormat
                      }}</b>
                    </td>
                  </template>
                  <template v-else>
                    <td>-</td>
                    <td>{{ l.Difference | commaFormat }}</td>
                    <td>-</td>
                  </template>
                  <td>{{ l.NewPoint | commaFormat }}</td>
                  <template v-if="l.Difference < 0">
                    <td>-</td>
                  </template>
                  <template v-else>
                    <td v-if="l.Deadline">{{ l.Deadline | dateFormat }}</td>
                    <td v-else>無期限</td>
                  </template>
                </tr>
              </table>
            </div>
            <div class="mtc_04">
              <table class="tableStyle1">
                <tr>
                  <th>到期日</th>
                  <th>到期點數</th>
                </tr>
                <tr v-if="PointDeadline.length == 0">
                  <td colspan="4">沒有到期的點數</td>
                </tr>
                <tr v-for="l in PointDeadline" :key="l.PointDeadlineID">
                  <td>{{ l.Deadline | dateFormat }}</td>
                  <td>
                    <b class="use_point noBorder">{{
                      l.Point | commaFormat
                    }}</b>
                  </td>
                </tr>
              </table>
            </div>
            <div class="mtc_05">
              <table class="tableStyle1">
                <tr>
                  <th>證照名稱</th>
                  <th>證照號碼</th>
                  <th>證照生效日(起始)</th>
                  <th>證照有效日(結束)</th>
                  <th>備註</th>
                </tr>
                <tr v-for="l in Licence" :key="l.UserLicenceID">
                  <td>{{ l.Name }}</td>
                  <td>{{ l.Serial }}</td>
                  <td>{{ l.StartTime | dateFormat }}</td>
                  <td>{{ l.EndTime | dateFormat }}</td>
                  <td>{{ l.Comment }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import user from "../plugins/user";

var map = {};
export default {
  data() {
    return {
      Lesson: [],
      Favorite: [],
      Point: [],
      PointDeadline: [],
      Licence: [],
      Me: null,
      UnlimitedPoint: 0,
      LimitedPoint: 0,
      LimitedDeadline: "",
    };
  },
  mounted() {
    this.verifyLoing();
    this.setBase("memberCenter");
    this.load();

    $(".mTab a").click(function () {
      var target = $(this).data("target");
      $(".mTab a").removeClass("on");
      $(this).addClass("on");
      $(".mTableContant div[class^=mtc]").hide();
      $("." + target).show();
    });
    $(".mTableContant div[class^=mtc]").hide();
    if (this.$route.query.p == "favorite") {
      $(".mTab a").removeClass("on");
      $(".mTab a[data-target=mtc_03]").addClass("on");
      $(".mtc_03").show();
    } else {
      $(".mtc_01").show();
    }
  },
  methods: {
    async load() {
      var result = await this.$api.getMyInfo();
      var me = await this.$api.getMe();
      this.Me = me;
      map = result.LessonMap;
      result.Lesson.forEach((l) => (l.Lesson = map[l.LessonID]));
      result.Favorite.forEach((l) => (l.Lesson = map[l.LessonID]));
      this.Lesson = result.Lesson;
      this.Favorite = result.Favorite;
      this.Point = result.Point;
      this.PointDeadline = result.Points;
      this.Licence = result.Licence;
      this.loadCustomJs();

      result.Points.forEach((p) => {
        if (p.Deadline == "2300-01-01T00:00:00") {
          this.UnlimitedPoint += p.Point;
        } else {
          this.LimitedPoint += p.Point;
          this.LimitedDeadline = p.Deadline;
        }
      });
    },
    async deleteFavorite(l) {
      await this.$api.removeFavoriteLesson(l.LessonID);
      this.Favorite.remove(l);
    },
    async logout() {
      user.logout();
      this.User = null;
      this.$router.replace('/');
    },
  },
  computed: {
    IsLearner() {
      if (!this.Me) {
        return false;
      }
      return this.Me.UserType.some(x => x == 16);
    }
  }
};
</script>